import { render, staticRenderFns } from "./choiceLang.vue?vue&type=template&id=741a804c&scoped=true&"
import script from "./choiceLang.vue?vue&type=script&lang=js&"
export * from "./choiceLang.vue?vue&type=script&lang=js&"
import style0 from "./choiceLang.vue?vue&type=style&index=0&id=741a804c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741a804c",
  null
  
)

export default component.exports