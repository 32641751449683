<template>
  <div class="form-page">
    
    <choiceLang />

    <div class="complete">
        <h2 v-if="fromInfo === 'R'">{{ restaurantCnrDesign.CompletedEnglish }}</h2>
        <h2 v-if="fromInfo === 'S'">{{ smokingZoneCnrDesign.CompletedEnglish }}</h2>

        <h1 v-if="fromInfo === 'R'">{{ restaurantCnrDesign.CompletedChinese }}</h1>
        <h1 v-if="fromInfo === 'S'">{{ smokingZoneCnrDesign.CompletedChinese }}</h1>

        <!-- <div class="info" style="margin-top: 2rem;">
          <div>
            <span>填寫時間 Submit Time： {{ time }}</span>
            <span>工號 Badge：{{ staffInfo.BadgeNo }}</span>
          </div>
          <div style="text-align: left;">
            <span>姓名 Name：{{ staffInfo.Name }}</span>
            <span v-if="seat !== null">座位號碼 Seat Number：{{ seat.color }}{{ seat.name }}</span>
            <span v-if="questionnaire !== null">座位號碼 Seat Number：{{ questionnaire.SeatNo }}</span>
          </div>
        </div> -->

        <div class="info" style="margin-top: 2rem;" v-if="external">
          <span>
            {{ i18n("Finish.SubmitTime") }}：
            <span class="time" style="color: #2660ee;">{{ time }}</span>
          </span>
          <span>
            {{ i18n("Finish.CompanyName") }}：
            <span style="color: #2660ee;">{{ staffInfo.CompanyName }}</span>
          </span>
          <span>
            {{ i18n("Finish.Name") }}： 
            <span style="color: #2660ee;">{{ staffInfo.Name }}</span>
          </span>

          <span>
            {{ i18n("Finish.VisitorType") }}： 
            <span style="color: #2660ee;">{{ staffInfo.VisitorType }}</span>
          </span>

        </div>

        <div class="info" style="margin-top: 2rem;" v-else>
          <span>
            {{ i18n("Finish.SubmitTime") }}：
            <span class="time" style="color: #2660ee;">{{ time }}</span>
          </span>
          <span>
            {{ i18n("Finish.Badge") }}：
            <span style="color: #2660ee;">{{ staffInfo.BadgeNo }}</span>
          </span>
          <span>
            {{ i18n("Finish.Name") }}： 
            <span style="color: #2660ee;">{{ staffInfo.Name }}</span>
          </span>
          <span v-if="seat !== null">
            <span class="info-tilte">{{ i18n("Finish.SeatNumber") }}：&emsp;&emsp;</span>
            <span style="color: #2660ee;margin-left: 0;" v-if="seat !== null">{{ seat.SmokeAreaChinese }} {{ seat.SeatNo }}</span>
            <span style="color: #2660ee;" v-if="seat !== null">({{ seat.SmokeAreaEnglish }} {{ seat.SeatNo }})</span>
          </span>
          <span v-if="questionnaire !== null">
            <span class="info-tilte">{{ i18n("Finish.SeatNumber") }}：&emsp;&emsp;</span>
            <span style="color: #2660ee;margin-left: 0;" v-if="currentPlace !== null">{{ currentPlace.BuildingChinese }}{{ currentPlace.AreaChinese }} {{ questionnaire.Seat }}</span>
            <span style="color: #2660ee;" v-if="currentPlace !== null">({{ currentPlace.BuildingEnglish }}-{{ currentPlace.AreaEnglish }} {{ questionnaire.Seat }})</span>
            <!-- <span style="color: #2660ee;" >{{ questionnaire.SeatNo }}</span> -->
          </span>
        </div>
        
        
    </div>
  </div>
</template>

<script>
/* global $ */
import moment from 'moment'
import { mapGetters, mapActions } from "vuex";

import choiceLang from '@/components/common/choiceLang.vue'

export default {
  components: {
    choiceLang
  },
  data() {
    return {
      staffInfo: {},
      seat: null, //  吸菸
      questionnaire: null,  //  餐廳
      currentPlace: null, // 餐廳地方資訊
      time: '',
      fromInfo: '', //  判斷用哪個完成頁面說明，R 是餐廳 S 是吸菸 
      external: false, //  外部疫調
    };
  },
  computed: {
    ...mapGetters(['restaurantCnrDesign', 'smokingZoneCnrDesign'])
  },
  methods: {
    ...mapActions(['getRestaurantCnrDesign', 'getSmokingZoneCnrDesign']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
  },
  created() {

    if(Object.keys(this.$route.params).length !== 0 && this.$route.params.constructor === Object) {
      this.staffInfo = JSON.parse(this.$route.params.staff)

      if(this.$route.params?.seat) {
        this.seat = JSON.parse(this.$route.params.seat) 
      }
       
      if(this.$route.params?.questionnaire) {
        this.questionnaire = JSON.parse(this.$route.params.questionnaire)   
      } 
      
      if(this.$route.params?.currentPlace) {
        this.currentPlace = JSON.parse(this.$route.params.currentPlace) 
      }

      if(this.$route.params?.external) {
        this.external = this.$route.params.external 
      }
      
    }

    if(this.currentPlace !== null) {
      this.getRestaurantCnrDesign()
      this.fromInfo = 'R'
    } else {
      this.getSmokingZoneCnrDesign()
      this.fromInfo = 'S'
    }

    this.time = moment().format('YYYY-MM-DD HH:mm');

    if (window.history && window.history.pushState) {
      $(window).on('popstate', function () {
        window.history.pushState('forward', null, '#');
        window.history.forward(1);
      });
    }
    
    window.history.pushState('forward', null, '#'); //在IE中必須得有這兩行
    window.history.forward(1);

  }
};
</script>

<style lang="scss" scoped>
.form-page {
  background-color: #bfe3bf;
  text-align: center;
  min-height: 100vh;
  padding: 90px 20px;
  // background-image: url("~@/assets/img/bg.png");
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 30px;
  }

  .form-title {
    width: 400px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 280px;
      margin: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text {
      
      background-image: url("~@/assets/img/title_bg.png");
      padding: 10px 20px;
      border-radius: 44px;
      color: #000;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }
    .page-number {
      span {
        font-size: 2em;
      }
    }
  }
  .form-wrap {
    width: 550px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      text-align: center;
      margin-bottom: 15px;

      span {
        display: inline-block;
        background: linear-gradient(rgba(#fff, 0) 60%, #fff 0%) !important;
        font-weight: bold;
        padding: 0 15px;
      }
    }

    .desc {
      font-size: 0.9em;
      line-height: 200%;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .field {
      input {
        border: none;
        padding: 0 10px;
        line-height: 40px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
        &:focus {
          outline: none;
        }
      }
    }

    .checkbox {
      display: block;
      cursor: pointer;

      .text {
        &:after {
          content: "\f058";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          color: #b6d3b6;
          position: absolute;
          left: 10px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          color: #d7e995;
          font-size: 1.2em;
        }

        position: relative;
        background-color: #97bd97;
        line-height: 40px;
        display: block;
        padding: 0 10px;
        padding-left: 40px;
        border-radius: 35px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        color: #fff;
      }

      input {
        display: none;
        &:checked {
          & + .text {
            background-color: #039577;
            color: #fff;
            &:after {
              color: #fff;
            }
          }
        }
      }
    }

    textarea {
      border: none;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .button-wrap {
      margin-top: 30px;
    }
  }

  .complete {
    background-color: #fff;
    width: 60%;
    height: 400px;
    margin: auto;
    border-radius: 6px;
    padding: 20px 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 10px;
    }

    h1 {
        margin-top: 1rem;
    }
  }
}

.info {

  display: flex;
  flex-wrap: wrap;
  text-align: left;
  // color: blue;
  font-weight: 900;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: 240px;

    .time {
      display: block;
      margin-bottom: 0;
    }
  }

  span {
    margin: 0 .6rem;
  }
}

.complete {
  
  h2 {
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  h1 {
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  span {
    @media screen and (max-width: 767px) {
      font-size: 18px;
      margin-bottom: .5rem;
      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (max-width: 500px) {
      font-size: 17px;
    }
  }
}

.info-tilte {
  margin: 0 !important;
}
</style>
