<template>
    <div>
        <button class="lang" data-toggle="modal" data-target="#languageModal">
            <eva-icon name="globe-outline" />
                {{ currentLanguage }}
        </button>

        <!-- 語系modal -->
        <div
            class="modal fade"
            id="languageModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="languageModal"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver v-slot="{ reset }" ref="form">
                        <form @reset.prevent="reset">
                        <div class="modal-header">
                            <span>請選擇語言</span>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                            <ValidationProvider
                                ref="forgetPassword"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <label>
                                語言
                                </label>
                                <select class="nav-select form-control" v-model="language">
                                <option
                                    v-for="item in languageList"
                                    :key="item.key"
                                    :value="item.key"
                                >
                                    {{ item.label }}
                                </option>
                                </select>
                                <span
                                    v-if="errors[0]"
                                    class="text-danger"
                                    style="font-size: 14px"
                                >
                                {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                            type="button"
                            class="btn btn-primary"
                            @click="selectLanguage"
                            >
                                確認
                            </button>
                        </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global $ */

export default {
    data() {
        return {
            // 多國語系
            currentLanguage: null,
            language: null,
            languageList: [
                { key: "zh", label: "繁體中文" },
                { key: "en", label: "English" },
                // { key: "vi", label: "Tiếng Việt" },
            ],
        }
    },
    computed: {
        selectedLanguage() {
            return this.$store.state.lang;
        },
    },
    watch: {
        selectedLanguage(e) {
            switch (e) {
                case "zh":
                this.currentLanguage = "繁體中文";
                break;
                case "en":
                this.currentLanguage = "English";
                break;
                case "vi":
                this.currentLanguage = "Tiếng Việt";
                break;
            }
            console.log(e);
        },
    },
    methods: {
        selectLanguage() {
            const value = this.language;
            this.$store.commit("setLang", value);
            this.$i18n.locale = value;
            localStorage.setItem("i18n-lang", value);
            $("#languageModal").modal("hide");
        }
    },
    created() {
        this.language = this.$store.state.lang;
        switch (this.$store.state.lang) {
            case "zh":
                this.currentLanguage = "繁體中文";
                break;
            case "en":
                this.currentLanguage = "English";
                break;
            case "vi":
                this.currentLanguage = "Tiếng Việt";
                break;
        }
    }
}
</script>


<style lang="scss" scoped>
.lang {
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;

  border: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 25px;
  transition: all .2s;
  margin-right: 10px;
  &:hover{
    background-color: rgba(0,0,0,.1);
  } 
}

.form-group {
    text-align: left;
}
</style>
